// .............    Toogle dropdown .......................

$(document).ready(function() {

	$('.DropDown')
		.each(function(){
			var $dropdown = $(this),
				total = $dropdown.data('total'),
				Toggle = $('<a class="Toggle">'+total+'</a>');

			$dropdown.prepend(Toggle);

			if (total == 1) {
				$dropdown.addClass('Single');
			}
		})
		.on('click', '.Toggle', function(e){
			e.preventDefault();
			$(this).parent().toggleClass('DropDown DropDownON')
		});

	$('.DropDownON')
		.on('click', '.Toggle', function(e){
			e.preventDefault();
			$(this).parent().toggleClass('DropDownON DropDown')
		});

});
