
// Shrink NavBar ......................................

var OpenNav;

$(document).ready(function() {

	var $Nav = $('nav#Nav'),
		$NavBar = $('.NavBar', $Nav),
		$PopWrapper = $('.PopWrapper', $Nav),
		$NavMain = $('.NavMain', $Nav),
		skipShrink = false;


    // Function : Shrink  .....................................
    function Shrink() {
		setTimeout(function(){

		if(skipShrink) {
			return;
			}

			$NavBar.addClass("NavBarShrink");
			}, 2000);
        }


    // Toggle Main Nav .....................................
    OpenNav = function(action) {
    	if ( action === undefined ) {
    		action = $PopWrapper.hasClass("PopWrapperON")
    			? 'close'
    			: 'open';
    	}

    	if (action == 'open') {
			console.log('Openning Nav');
			$PopWrapper.addClass("PopWrapperON");
			$("body").addClass("Lock");
    	} else {
			console.log('Closing Nav');
			$PopWrapper.removeClass("PopWrapperON");
			$("body").removeClass("Lock");
    	}
	}


	// Nav Bar hover effect handling
	$NavBar.on("mouseenter", function() {
		skipShrink = true;
	}).on("mouseleave", function() {
		skipShrink = false;
		Shrink();
	});


	// Nav "Hamberger" menu button
	$NavBar.on("click", 'a.Hamberger', function(e){
		e.preventDefault();
		OpenNav();
	});


	// Have all nav links close the nav menu when clicked (helpful for anchored
	// links within same page)
	$NavMain.on('click', 'a', function(e){
		OpenNav('close');
	});


	// Scroll triggered menu shrink handling
	$(window).scroll(function() {
		$NavBar.toggleClass("NavBarShrink", $(this).scrollTop() > 1);

		if($(this).scrollTop() == 0){
			Shrink();
		};
	});

	Shrink();

});
