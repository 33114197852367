// Sticky Header

$(document).ready(function() {

	$(window).scroll(function() {

		if ($(window).scrollTop() > 100) {
			$('.main_h').addClass('sticky');
		} else {
			$('.main_h').removeClass('sticky');
		}
	});

	// Mobile Navigation
	$('.mobile-toggle').click(function() {
		if ($('.main_h').hasClass('open-nav')) {
			$('.main_h').removeClass('open-nav');
		} else {
			$('.main_h').addClass('open-nav');
		}
	});

	$('.main_h li a').click(function() {
		if ($('.main_h').hasClass('open-nav')) {
			$('.navigation').removeClass('open-nav');
			$('.main_h').removeClass('open-nav');
		}
	});

	// navigation scroll lijepo radi materem
	$('nav a[href^="#"]').click(function(event) {
		var id = $(this).attr("href"),
			target = $(id),
			offset = 70;

		if (target.length) {
			event.preventDefault();
			target = target.offset().top - offset;
			$('html, body').animate({
				scrollTop: target
			}, 500);
		}
	});

});
