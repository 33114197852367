$(document).ready(function(){

	var $body = $('body'),
		$MainPane = $('.MainPane', $body),
		$Logistic = $('nav.Logistic ul', $MainPane),
		$CollectionPane = $('body.CollectionPage .CollectionPane'),
		$Collections = $('.CollectionPane .Collection', $body);


	// Setup QuickLinks on Monthly Calendar pages.
	if ($Collections.length) {

		var $links;

		// Setup CollectionAnchor on Monthly Calendar pages.
		if ($body.hasClass('EventPage') && $Logistic.length) {
			$links = $('<li class="CollectionAnchor" />');
			$Logistic.append($links);
		}

		// Setup as Logistic's CollectionAnchor on Event pages.
		else if ($body.hasClass('MonthlyPage') && $MainPane.length) {
			$links = $('<div class="QuickLinks"/>');
			$links.prependTo($MainPane).wrap('<nav />');
		}

		// Loop all the existing collections within the collection pane and get
		// a list of links.
		if ($links) {
			$Collections.each(function(){
				// Clone the leading anchor tag (ie. the collection banner).
				$a = $(this).children('a:first-child').clone();
				// Convert it's h3 to a p element.
				$a.find('h3').replaceWith(function(){
					return $("<p />").append($(this).contents());
				});
				// Append it to our links list.
				$links.append($a);
			});
		}
	}

	// Setup Logistic's EventsAnchor on Collection pages.
	if ($CollectionPane.length && $Logistic.length) {

		var $EventsAnchor = $('<li class="EventsAnchor"></li>'),
			total = $CollectionPane.find('.FeaturedEvents a').length;
		
		if (total > 0) {
			// Add an in page anchor within our EventsAnchor, with the total
			// number of existing events, to the Collection page.
			$EventsAnchor.append('<a href="#CollectionPane"><p><span>'+total+'</span>Evénements au programme</p></a>');

			$Logistic.append($EventsAnchor);
		}
	}

});
