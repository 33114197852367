  
  
  // This forces HEIGHT to to be constant for ALL broesers - Bug in older vertion of Safari  
  
  
  // ............  D E F A U L T ......................................
  
$(document).ready(function() {
	
	height();

	$(window).resize(function() {
		height();
	});
	
	
	function height() {
		//default
		$('.TemplateHead').height($(window).height()*0.85);
		
		// Calendar + Monthly + News
		$('.CalendarPage .TemplateHead').height($(window).height()*0.20);
		$('.MonthlyPage .TemplateHead').height($(window).height()*0.20);
		$('.NewsPage .TemplateHead').height($(window).height()*0.20);

		//Services
		$('.ServicesHome .TemplateHead').height($(window).height()*0.85);
	};
	
});

