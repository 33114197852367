$(document).ready(function() {

	$(document).on('click', '.PopShare a', function(e){
		e.preventDefault();

		var $a = $(this),
			type = $a.attr('class'),
			href = $a.attr('href'),
			enc_url = encodeURIComponent(document.URL)
			enc_title = encodeURIComponent(document.title),
			target = '_blank';

		switch (type) {

			case 'facebook':
				href += '?u=' + enc_url + '&t=' + enc_url;
			break;

			case 'twitter':
				href += '?text=' + enc_title + '&url=' + enc_url;
			break;

			case 'linkedin':
				href += '?mini=true&url=' + enc_url + '&title=' + enc_title;
			break;

			case 'mail':
				href += '?body=' + enc_url;
				target = '_self';
			break;

			default: return false;
		}

		window.open(href, target);
	});

});