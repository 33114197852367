/**
 * AFY Swiper Initialization
 */

$(document).ready(function() {

	var $swiperContainer = $('.swiper-container');

	if ($swiperContainer.length) {
		var swiper = new Swiper($swiperContainer, {
		  slidesPerView: 1,
		  spaceBetween: 30,
		  /*effect: 'fade',*/
		  loop: true,
		  autoplay: {
			delay: 3500,
			disableOnInteraction: false,
		  },
		  pagination: {
			el: '.swiper-pagination',
			clickable: true,
		  },
		  navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		  },
		});
    }

});