// .............    Toogle Pop ups .......................

$(document).ready(function() {


	function ClosePopUps(){
		$('.PopUpON').removeClass('PopUpON').addClass('PopUp');
		$('body').removeClass('Lock')
	}


	$('a[href^="/popup/"], a[data-popup^="Pop"]').click(function(e){
		e.preventDefault();

		// Setup all our parameters.
		var $a = $(this),
			href  = $a.attr('href'),
			cache = $a.data('cache'),
			popup = $a.data('popup'),
			path  = $a.data('path'),
			query = $a.data('query'),
			segs  = [],
			name  = '',
			url   = '',
			$popup;

		// Check if we have a popup path set, otherwise take it from href.
		if (path === undefined) {
			path = href;
		}

		// Strip off any protocol/domain from domain.
		path = path.replace(/^https?:\/\/[^\/]+/, '');

		// Break the path into an array of segments for easy parsing.
		segs = path.replace(/^\/|\/$/, '').split('/');

		// Determine a popup name and ajax query url based on wether the anchor
		// was selected as a popup by href, or data-popup. For the former we'll
		// pull straight of segment stack (effective from href). For the latter,
		// we'll take the name directly from the data attribute, and build the
		// url accordingly.
		if (popup === undefined) {
			name = segs[1];
			url = href;
		} else {
			// Parse URL segments first by checking for a language identifier.
			if (segs[0] == 'en') {
				url += '/' + segs.shift();
			}
			// Check if the next 2 segments represent the popup template itself.
			// If so, we'll strip those off and leave all remaining segments.
			if (segs[0] == 'popup' && segs[1] == popup) {
				segs.splice(0,2);
			}
			// Otherwise, we'll assume all but the last segment should be left
			// as the sole "variable" to include.
			else {
				segs.splice(0, segs.length - 1);
			}
			name = popup;
			url += '/popup/' + popup + '/' + segs.join('/');
		}

		// Define our popup element.
		$popup = $('div#'+name);

		// If this named popup already exists, and caching is set, just load
		// that one.
		if ($popup.length && cache) {
			$popup.addClass('PopUpON');
			$('body').addClass('Lock');
			return;
		}

		// Otherwise, request a fresh popup.
		if (query) url += '?' + query;
		$.get(url, function(data){
			$('body').addClass('Lock');

			if ($popup.length) {
				$popup.replaceWith(data);
			} else {
				$('body').append(data);
			}
		});

	});



	$(document).on('click', '.PopUpON > a', function(e){
		e.preventDefault();
		ClosePopUps();
	});



	$(document).on('keydown', function(e){
		e = e || window.event;
		var isEscape = false;
		if ("key" in e) {
			isEscape = (e.key == "Escape" || e.key == "Esc");
		} else {
			isEscape = (e.keyCode == 27);
		}
		if (isEscape) {
			ClosePopUps();
		}
	});

});
